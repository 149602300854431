import instance from "../../axios";

export default {
  namespaced: true,
  state: {
    stateProvinces: []
  },
  mutations: {
    setStateProvinces(state, data) {
      state.stateProvinces = data
    }
  },
  actions: {
    searchCEP({ commit }, cep) {
      return instance
        .get(
          `/api/Location/SearchCEP?cep=${cep}`
        )
        .then(response => response.data.Response);
    },

    getCities({ commit }, id) {
      return instance
        .get(
          `/api/Location/GetCities?stateProvinceId=${id}`
        )
        .then(response => response.data.Response);
    },

    getStateProvinces({ commit, getters }) {
      if (!getters.stateProvinces || !getters.stateProvinces.length)
        return instance
          .get(
            `/api/Location/GetStateProvinces`
          )
          .then(response => response.data.Response)
          .then(response => commit('setStateProvinces', response));
    },
  },
  getters: {
    stateProvinces(state) {
      return state.stateProvinces || [];
    }
  }
};