import axios from "axios";
import * as qs from "qs";
import axiosInstance from "@/axios";
import environment from "@/environment";
import perfilHelpers from "@/helpers/PerfilHelpers";
import jwt_decode from "jwt-decode";

const AUTH_DATA = "auth_data";
const ADVISOR_INFO = "advisor_info";

//auth-data
function saveAuthDataToStorage(data) {
  if (data) {
    localStorage.setItem(AUTH_DATA, JSON.stringify(data));
  } else {
    localStorage.removeItem(AUTH_DATA);
  }
}

function loadAuthDataFromStorage() {
  const json = localStorage.getItem(AUTH_DATA);
  return json ? JSON.parse(json) : null;
}

//advisor
function saveAdvisorInfoToStorage(data) {
  if (data) {
    localStorage.setItem(
      ADVISOR_INFO,
      JSON.stringify({
        PersonId: data.PersonId,
        Username: data.Username,
        PersonUniqueId: data.PersonUniqueId
      })
    );
  } else {
    localStorage.removeItem(ADVISOR_INFO);
  }
}

function loadAdvisorFromStorage() {
  const json = localStorage.getItem(ADVISOR_INFO);
  return json ? JSON.parse(json) : null;
}

export default {
  namespaced: true,
  state: {
    authData: undefined,
    userInfo: {},
    storageInfo: {},
    advisorInfo: {},
    dadosGenerateToken: {},
    notificacoes: [],
    all: []
  },
  mutations: {
    addNotification(state, row) {
      state.notificacoes.push(row);
      let src =
        "https://www.myinstants.com/media/sounds/meet-message-sound-1.mp3";
      let audio = new Audio(src);
      audio.play();
    },
    addNotifications(state, row) {
      var Group_ID = null;
      var auth_data = JSON.parse(localStorage.getItem("auth_data"));
      var decoded = jwt_decode(auth_data.access_token);
      Group_ID = decoded.UID;
      row.map(item => {
        if (item.isRead == false) {
          state.notificacoes.push(item);
        } else {
          let userReadID = item.usersRead ? JSON.parse(item.usersRead) : [];
          if (userReadID.includes(Group_ID)) {
            state.all.push(item);
          } else {
            state.notificacoes.push(item);
          }
        }
      });
    },
    setAuthData(state, data) {
      state.authData = data;
      saveAuthDataToStorage(data);
    },
    setUserInfo(state, data) {
      state.userInfo = data;

      perfilHelpers.menuHomeRouterName();
    },
    setStorageInfo(state, data) {
      state.storageInfo = data;
    },
    setAdvisorInfo(state, data) {
      state.advisorInfo = data;
      saveAdvisorInfoToStorage(data);
    },
    setDadosGenerateToken(state, data) {
      state.dadosGenerateToken = data;
    },
    clear(state, data) {
      state.all = [];
      state.notificacoes = [];
    }
  },
  actions: {
    loadAuthData({ commit, dispatch, getters }) {
      commit("setAuthData", loadAuthDataFromStorage());
      if (getters["isAuthenticated"]) {
        return Promise.all([
          dispatch("fetchUserInfo"),
          dispatch("fetchStorageInfo")
        ]);
      }
    },

    loginAdvisor({ commit, dispatch }, data) {
      return axiosInstance({
        method: "post",
        url: "/api/Advisor/AuthenticationBroker",
        data
      })
        .then(({ data }) => commit("setAuthData", data))
        .then(() =>
          Promise.all([dispatch("fetchUserInfo"), dispatch("fetchStorageInfo")])
        );
    },

    returnAdvisor({ commit, dispatch }, data) {
      return axiosInstance({
        method: "post",
        url: "/api/Advisor/AuthenticationAdvisor",
        data
      })
        .then(({ data }) => commit("setAuthData", data))
        .then(() =>
          Promise.all([dispatch("fetchUserInfo"), dispatch("fetchStorageInfo")])
        );
    },

    generateToken({}, payload) {
      const applicationConfig = environment.getApplicationConfig();
      return axios
        .post(`${((applicationConfig || {}).sso || {}).url}/generate/token`, {
          ...payload,
          clientId: ((applicationConfig || {}).sso || {}).clientId
        })
        .then(({ data }) => data);
    },

    async loginLegacy({ commit, dispatch, getters }, data) {
      const applicationConfig = environment.getApplicationConfig();
      return axios({
        method: "post",
        url: `${((applicationConfig || {}).sso || {}).url}/connect/token`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
          ...data,
          client_id: ((applicationConfig || {}).sso || {}).clientId,
          scope: `${
            ((applicationConfig || {}).sso || {}).scope
          } offline_access`,
          client_secret: ((applicationConfig || {}).sso || {}).clientSecret
        })
      })
        .then(({ data }) => commit("setAuthData", data))
        .then(() =>
          Promise.all([dispatch("fetchUserInfo"), dispatch("fetchStorageInfo")])
        )
        .then(() => {
          const userInfo = getters["userInfo"];
          //data.advisor = quando loga pelo login assessor
          //UserTypeId 6 = quando é assessor
          if (data.advisor || userInfo.UserTypeId == 6) {
            commit("setAdvisorInfo", userInfo);
          }
        });
    },

    async login({ commit, dispatch, getters }, data) {
      const applicationConfig = environment.getApplicationConfig();
      const companyKey = (applicationConfig || {}).companyKey;
      return await axios({
        method: "post",
        url: `${((applicationConfig || {}).sso || {}).url}/api/Security/GetToken`,
        headers: {
          companyKey,
          "Content-Type": "application/json"
        },
        data: {
          ...data,
          grant_type: "password",
          scope: `${
            ((applicationConfig || {}).sso || {}).scope
          } offline_access`
        }
      })
        .then(({ data }) => commit("setAuthData", data))
        .then(() =>
          Promise.all([dispatch("fetchUserInfo"), dispatch("fetchStorageInfo")])
        )
        .then(() => {
          const userInfo = getters["userInfo"];
          //data.advisor = quando loga pelo login assessor
          //UserTypeId 6 = quando é assessor
          if (data.advisor || userInfo.UserTypeId == 6) {
            commit("setAdvisorInfo", userInfo);
          }
        });
    },

    async loginSSO({ commit, dispatch, getters }, data) {
      const applicationConfig = environment.getApplicationConfig();
      const companyKey = (applicationConfig || {}).companyKey;
      return await axios({
        method: "post",
        url: `${((applicationConfig || {}).sso || {}).url}/api/Security/GetToken`,
        headers: {
          companyKey,
          "Content-Type": "application/json"
        },
        data: {
          ...data,
          grant_type: "authorization_code",
          scope: `${
            ((applicationConfig || {}).sso || {}).scope
          } offline_access`
        }
      })
        .then(({ data }) => commit("setAuthData", data))
        .then(() =>
          Promise.all([dispatch("fetchUserInfo"), dispatch("fetchStorageInfo")])
        )
        .then(() => {
          const userInfo = getters["userInfo"];
          //data.advisor = quando loga pelo login assessor
          //UserTypeId 6 = quando é assessor
          if (data.advisor || userInfo.UserTypeId == 6) {
            commit("setAdvisorInfo", userInfo);
          }
        });
    },

    async refreshTokenLegacy({ commit, dispatch, getters: { authData } }) {
      const applicationConfig = environment.getApplicationConfig();
      return axios({
        method: "post",
        url: `${((applicationConfig || {}).sso || {}).url}/connect/token`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: qs.stringify({
          grant_type: "refresh_token",
          scope: ((applicationConfig || {}).sso || {}).scope,
          client_id: ((applicationConfig || {}).sso || {}).clientId,
          client_secret: ((applicationConfig || {}).sso || {}).clientSecret,
          refresh_token: authData.refresh_token
        })
      }).then(({ data }) => {
        commit("setAuthData", data);
      });
    },

    async refreshToken({ commit, dispatch, getters: { authData } }) {
      const applicationConfig = environment.getApplicationConfig();
      const companyKey = (applicationConfig || {}).companyKey;
      return await axios({
        method: "post",
        url: `${((applicationConfig || {}).sso || {}).url}/api/Security/GetToken`,
        headers: {
          companyKey,
          "Content-Type": "application/json"
        },
        data: {
          grant_type: "refresh_token",
          scope: ((applicationConfig || {}).sso || {}).scope,
          refresh_token: authData.refresh_token
        }
      }).then(({ data }) => {
        commit("setAuthData", data);
      });
    },

    logout({ commit }) {
      commit("setAuthData", undefined);
      commit("setAdvisorInfo", undefined);
      commit("setUserInfo", {});
      commit("setStorageInfo", {});
      setTimeout(() => {
        commit("clear", []);
      }, 200);
    },

    fetchUserInfo({ commit, getters: { token }, context }) {
      return axiosInstance({
        method: "get",
        url: "/api/Security/GetUserInfo",
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        if (response && response.data) {
          commit("setMenuItens", null, { root: true });
          commit("perfil-module/setMenuFuncionalities", null, { root: true });
          commit("usuario-module/updateTiposUsuario", [], { root: true });
          commit("setUserInfo", response.data.Response);
          // setTimeout(() => {
          //   var Group_ID = null;
          //   if (perfilHelpers.isInsurance()) {
          //     var lsApplicationConfig = JSON.parse(
          //       localStorage.getItem("lsApplicationConfig")
          //     );
          //     Group_ID = lsApplicationConfig.CompanyId + "_Insurance";
          //   } else {
          //     var auth_data = JSON.parse(localStorage.getItem("auth_data"));
          //     var decoded = jwt_decode(auth_data.access_token);
          //     Group_ID = decoded.BID;
          //   }
          //   return axios({
          //     method: "post",
          //     url: environment.hub_Url + `/api/notifications/GetNotifications`,
          //     headers: {
          //       Api_Key: environment.api_Key,
          //     },
          //     data: {
          //       groupId: Group_ID,
          //       message: "Push All",
          //       status: "Enviado",
          //     },
          //   }).then(({ data }) => {
          //     commit("addNotifications", data);
          //   });
          // }, 4000)
        }
      });
    },
    fetchStorageInfo({ commit, getters: { token } }) {
      //mockup
      commit("setStorageInfo", {
        Uri: "Uri",
        ContainerName: "ContainerName",
        Signature:
          "Signature",
        ExpirationDate: "2099-01-29T03:30:03.2130661-03:00"
      });
    },

    requestRecoveryPassword({}, payload) {
      return axiosInstance
        .post("/api/Security/RequestRecoveryPassword", payload)
        .then(response => response.data.Response);
    }
  },
  getters: {
    notificacoes: state => {
      return state.notificacoes;
    },
    isAuthenticated(state) {
      return !!state.authData;
    },
    authData(state) {
      return state.authData;
    },
    token(state) {
      return state.authData ? state.authData.access_token : null;
    },
    userInfo(state) {
      return state.userInfo;
    },
    storageInfo(state) {
      return state.storageInfo;
    },
    advisorInfo(state) {
      return state.advisorInfo && state.advisorInfo.PersonId
        ? state.advisorInfo
        : loadAdvisorFromStorage();
    },

    authType() {
      const applicationConfig = environment.getApplicationConfig();
      return (((applicationConfig || {}).sso || {}).authentication || {}).type;
    },

    dadosGenerateToken(state) {
      return state.dadosGenerateToken;
    }
  }
};
