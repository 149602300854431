var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popup-base",
    {
      staticClass: "loading-modal onpoint-loadingmodal",
      attrs: {
        title: " ",
        size: "md",
        showModal: _vm.active,
        buttonCloseHidden: true
      },
      on: {
        close: function($event) {
          _vm.active = $event
        }
      }
    },
    [
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "flex flex-col items-center pt-4" }, [
          _c("div", {
            staticClass: "vs-con-loading__container",
            attrs: { id: "div-with-loading" }
          }),
          _c(
            "h2",
            {
              staticClass:
                "flex flex-col items-center text-center font-semibold mt-8"
            },
            [_vm._v("\n        Aguarde\n      ")]
          ),
          _c("p", { staticClass: "text-center my-8 mx-2" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.message || "Estamos processando a solicitação.") +
                "\n      "
            )
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }