const cpf = {
    validate: value => {
        //eslint-disable-next-line
        const re = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/;
        return re.test(value);
    },
    message: "CPF inválido"
};

const email = {
    validate: value => {
        //eslint-disable-next-line
        const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
        return re.test(value);
    },
    message: "E-mail inválido"
};

const minInsurancePremium = {
    validate: (value, args) => {
      const min = (args[0]) * (args[2] / 100);
        if (value < min) {
            return `O valor de importância segurada mínimo para a modalidade "${
        args[1]
      }" é de ${new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(min)}`;
        }
        return true;
    }
};

const maxInsurancePremium = {
    validate: (value, args) => {
        if (value > args[0]) {
            return `O valor de importância segurada máximo para a modalidade "${
        args[1]
      }" é de ${new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(args[0])}`;
        }
        return true;
    }
};

const isNumeric = {
    validate: value => {
        return !isNaN(value);
    },
    message: "Valor inválido inválido, campo numérico."
};

const mindays = {
    validate: (value, args) => {
        const min = args[0];
        if (value < min) {
            return `Prazo de dias para essa modalidade não pode ser menor que ${args[0]}`;
        }
        return true;
    }
};

const maxdays = {
    validate: (value, args) => {
        const min = args[0];
        if (value > min) {
            return `Prazo de dias para essa modalidade não pode ser maior que ${args[0]}`;
        }
        return true;
    }
};

const minNumber = {
    validate: (value, args) => {
        const min = args[0];
        if (value < min) {
            return `O valor não pode ser menor que ${min}`;
        }
        return true;
    }
};

const maxNumber = {
    validate: (value, args) => {
        const max = args[0];
        if (value > max) {
            return `O valor não pode ser maior que ${max}`;
        }
        return true;
    }
};

const phone = {
    validate: value => {
        //eslint-disable-next-line

        const re = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/;

        return re.test(value);
    },

    message: "Telefone inválido"
};

export {
    cpf,
    email,
    minInsurancePremium,
    maxInsurancePremium,
    phone,
    isNumeric,
    mindays,
    maxdays,
    minNumber,
    maxNumber
};
