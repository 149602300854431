// Tailwind
import "@/assets/css/main.css";
//date
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import "material-icons/iconfont/material-icons.css"; //Material Icons
// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';
//vee-validate
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import Vue from "vue";
//currency
import VueCurrencyInput from "vue-currency-input";
import vueDebounce from "vue-debounce";
import TreeView from "vue-json-tree-view/dist/vue-json-tree-view.min.js";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
// Global Register multiselect css
import "vue-multiselect/dist/vue-multiselect.min.css";
// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
// Vuesax Component Framework
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css"; // Vuesax
// Theme Configurations
import "../themeConfig.js";
import App from "./App.vue";
// Styles: SCSS
import "./assets/scss/main.scss";
// axios
import axios from "./axios.js";
import environment from "./environment";
// Filters
import "./filters/filters.js";
import "./filters/iconeTimeline.js";
import "./filters/labelBanco.js";
import "./filters/masks.js";
import "./filters/setColorStatus.js";
import "./filters/statusDocumento.js";
import "./filters/statusSusep.js";
import onPointFunctions from "./functions";
// Globally Registered Components
import "./globalComponents.js";
// Vue Router
import router from "./router";
// Vuex Store
import store from "./store/index";
// utils
import * as utils from "./utils/utils";
import * as enums from "./utils/enums";
import * as validators from "./validators";

//Application Insights
import VueAppInsights from "vue-application-insights";
Vue.use( VueAppInsights, {
  id: process.env.VUE_APP_APPLICATION_INSIGHTS_KEY,
  router
});


// Vue.prototype.$appInsights.config.maxBatchInterval = 0;  // Envia imediatamente
// Vue.prototype.$appInsights.config.maxBatchSize = 1; // Um evento por vez

import "jodit/build/jodit.min.css";
import JoditVue from "jodit-vue";
Vue.use(JoditVue);

import CKEditor from "ckeditor4-vue";
Vue.use(CKEditor);

Vue.prototype.$eventHub = new Vue();

import ElementUI from "element-ui";
import locale from "../src/pt";

import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI, { locale });

Vue.use(Vuesax);

const BaseVsPopup = Vue.options.components.VsPopup;
const VsPopup = BaseVsPopup.extend({
  props: {
    closeOnClickOutside: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    close(event, con) {
      if (con) {
        if (
          event.target.className &&
          event.target.className.indexOf &&
          event.target.className.indexOf("vs-popup--background") != -1 &&
          this.closeOnClickOutside // add this condition
        ) {
          this.$emit("update:active", false);
          this.$emit("close", false);
        } else if (
          !this.buttonCloseHidden &&
          event.srcElement == this.$refs.btnclose.$el
        ) {
          this.$emit("update:active", false);
          this.$emit("close", false);
        }
      }
    }
  }
});
Vue.component("VsPopup", VsPopup);
Vue.prototype.$http = axios;
Vue.prototype.$utils = utils;
Vue.prototype.$enums = enums;

Vue.use(VueHammer);

// Feather font icon
require("./assets/css/iconfont.css");
require("./assets/css/themify-icons.css");

localize({ pt_BR });
localize("pt_BR");

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Object.keys(validators).forEach(validator =>
  extend(validator, validators[validator])
);

Vue.config.productionTip = !environment.production;

Vue.use(vueDebounce);
Vue.use(TreeView);

Vue.mixin({
  beforeCreate() {
    if (!this.$onpoint) {
      this.$onpoint = Vue.observable({});
      onPointFunctions(Vue);
    }
  }
});

//moment
Vue.use(require("vue-moment"));

const pluginOptions = {
  globalOptions: { currency: { prefix: "R$ " }, locale: "br" }
};
Vue.use(VueCurrencyInput, pluginOptions);

async function loadStore() {
  await store.dispatch("auth/loadAuthData");
}

Vue.use(VueFilterDateFormat);

const initializeVue = () => {
  loadStore()
    .then(() => {
      new Vue({
        store,
        router,
        render: h => h(App)
      }).$mount("#app");
    })
    .catch(ex => {
      if (((ex || {}).response || {}).status == 401) {
        //Acesso negado ao recuperar os dados do usuário logado
        //vamos tentar reautenticar o token nesse caso.

        //Seta o refreshtoken como false, pois não precisa.
        //Como o axios é compartilhado, vamos definir isso aqui.
        return store
          .dispatch("auth/refreshToken")
          .then(data => {
            return loadStore();
          })
          .catch(exRefreshToken => {
            //Seta o refreshtoken como false, pois não precisa.
            //Força logout
            store.dispatch("auth/logout");
          })
          .finally(() => {
            new Vue({
              store,
              router,
              render: h => h(App)
            }).$mount("#app");
          });
      } else {
        //Seta o refreshtoken como false, pois não precisa.
        store.dispatch("setNeedRefreshToken", false);
        //Força logout
        store.dispatch("auth/logout");
        new Vue({
          store,
          router,
          render: h => h(App)
        }).$mount("#app");
      }
      //store.dispatch('auth/logout');
    });
};

const applicationConfig = environment.getApplicationConfig();
let dns = location.host;
if (location.pathname.indexOf("/login") > -1) {
  const parts = location.pathname.split("/");
  if (parts.length == 3 && parts[parts.length - 1]) {
    const newDNS = parts[parts.length - 1];
    if (newDNS.length > 7 && newDNS.substring(0, 7) == "simula-")
      dns = newDNS.substring(7);
  }
} else if (applicationConfig && applicationConfig.dns) {
  dns = applicationConfig.dns;
}

axios({
  method: "get",
  url: `/api/Application/GetPortalConfig?dns=${dns}`
})
  .then(response => {
    environment.setApplicationConfig(response.data.Response);
    initializeVue();
  })
  .catch(ex => {
    initializeVue();
  });
