var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "con-upload" },
    [
      _vm.viewActive
        ? _c("az-view-upload", { attrs: { src: _vm.viewSrc } })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "con-img-upload" },
        [
          _vm._l(_vm.files, function(file, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "img-upload",
                class: {
                  fileError: file.error
                }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn-x-file",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.removeFile(file)
                      }
                    }
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons notranslate",
                        attrs: { translate: "no" }
                      },
                      [_vm._v(" clear ")]
                    )
                  ]
                ),
                _vm.showUploadButton
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-upload-file",
                        class: {
                          "on-progress": file.getProgress(),
                          "ready-progress": file.getProgress() >= 100
                        },
                        style: {
                          height:
                            (file.getProgress() > 0
                              ? file.getProgress()
                              : undefined) + "%"
                        }
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons notranslate",
                            attrs: { translate: "no" }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  file.getProgress() >= 100
                                    ? file.error
                                      ? "report_problem"
                                      : "cloud_done"
                                    : ""
                                ) +
                                "\n        "
                            )
                          ]
                        ),
                        _c("span", [_vm._v(_vm._s(file.getProgress()) + " %")])
                      ]
                    )
                  : _vm._e(),
                file.src
                  ? _c("img", {
                      key: index,
                      style: {
                        maxWidth: file.orientation == "h" ? "100%" : "none",
                        maxHeight: file.orientation == "w" ? "100%" : "none"
                      },
                      attrs: { src: file.src },
                      on: {
                        touchend: function($event) {
                          return _vm.viewImage(file.src, $event)
                        },
                        click: function($event) {
                          return _vm.viewImage(file.src, $event)
                        }
                      }
                    })
                  : _vm._e(),
                !file.src
                  ? _c("h4", { staticClass: "text-archive" }, [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons notranslate",
                          attrs: { translate: "no" }
                        },
                        [_vm._v(" description ")]
                      ),
                      _c("span", [
                        _vm._v(
                          "\n          " + _vm._s(file.name) + "\n        "
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            )
          }),
          (_vm.singleUpload && (_vm.files || []).length < 1) ||
          _vm.singleUpload == false
            ? _c(
                "div",
                {
                  staticClass: "con-input-upload",
                  class: {
                    "on-progress-all-upload": _vm.percent != 0,
                    "is-ready-all-upload": _vm.percent >= 100,
                    "disabled-upload":
                      _vm.$attrs.hasOwnProperty("disabled") || _vm.limit
                        ? _vm.srcs.length - _vm.itemRemove.length >=
                          Number(_vm.limit)
                        : false
                  }
                },
                [
                  _c(
                    "input",
                    _vm._b(
                      {
                        ref: "fileInput",
                        attrs: {
                          disabled:
                            _vm.$attrs.disabled || _vm.limit
                              ? _vm.srcs.length - _vm.itemRemove.length >=
                                Number(_vm.limit)
                              : false,
                          type: "file"
                        },
                        on: { change: _vm.getFiles }
                      },
                      "input",
                      _vm.$attrs,
                      false
                    )
                  ),
                  _c("span", { staticClass: "text-input" }, [
                    _vm._v("\n        " + _vm._s(_vm.text) + "\n      ")
                  ]),
                  _c("span", {
                    staticClass: "input-progress",
                    style: {
                      width: _vm.percent + "%"
                    }
                  }),
                  _vm.showUploadButton
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn-upload-all vs-upload--button-upload",
                          attrs: {
                            disabled: _vm.filesx.length == 0,
                            type: "button",
                            title: "Upload"
                          },
                          on: {
                            click: function($event) {
                              return _vm.upload("all")
                            }
                          }
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass: "material-icons notranslate",
                              attrs: { translate: "no" }
                            },
                            [_vm._v("\n          cloud_upload\n        ")]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }