import instance from "../../axios";

export default {
  namespaced: true,
  state: {
    tomador: {},
    novaPropostaLimitesETaxasTomador: [],
    searchTomadores: []
  },
  mutations: {
    updateTomador(state, tomador) {
      state.tomador = tomador;
    },

    updateTomadorLimitesETaxas(state, limitesETaxas) {
      state.novaPropostaLimitesETaxasTomador = limitesETaxas;
    },

    updateSearchTomadores(state, data) {
      state.searchTomadores = data;
    }
  },
  actions: {
    async saveComplementarData({}, payload) {
      return await instance
        .post(`/api/PolicyHolder/SaveComplementarData`, payload)
        .then(response => response.data.Response);
    },

    async getHistory({}, id) {
      return await instance
        .get(`/api/PolicyHolderChangeHistory/Get?policyHolderId=${id}`)
        .then(response =>
          response.data.Response.map(item => {
            return {
              ...item,
              ChangedByName: (item.ChangesHistories ||
                item.LocationChangesHistories || [])[0].ChangedByName
            };
          })
        );
    },

    async updatePolicyHolderName({}, payload) {
      return await instance
        .post(
          `/api/PolicyHolder/UpdatePolicyHolderName`,
          {
            PolicyHolderUniqueId: payload.PolicyHolderUniqueId,
            Name: payload.Name
          }
        )
        .then(response => response.data.Response);
    },

    async removeLocation({}, payload) {
      return await instance
        .delete(
          `/api/PolicyHolder/RemovePolicyHolderLocation?locationId=${payload.locationId}`
        )
        .then(response => response.data.Response);
    },

    async carregarTomador({ commit }, policyHolderUniqueId) {
      return await instance
        .get(
          `/api/PolicyHolder/GetPolicyHolder?UniqueId=${policyHolderUniqueId}`
        )
        .then(response => response.data.Response)
        .then(data => commit("updateTomador", data) || data);
    },

    async validarTomadorPorCnpj({ commit }, cnpj) {
      return await instance
        .post(
          `api/PolicyHolder/ValidatePolicyHolderByCNPJToNewProposal?Cnpj=${cnpj}`
        )        
        // .then(data => commit('updateTomador', data) || data)
        .then(response => response.data.Response);
    },

    async searchTomadores({ commit }, params) {
      const url = `policyHolderName=${params.filter.policyHolderName}&policyHolderDocument=${params.filter.policyHolderDocument}&currentPage=${params.currentPage}&rowsPerPage=${params.rowsPerPage}`;
      return await instance
        .get(
          `/api/PolicyHolder/SearchPolicyHoldersRelatedToCurrentUserLogged?${url}`
        )
        .then(response => response.data.Response)
        .then(
        response => commit("updateSearchTomadores", response) || response
        );        
    },

    async solicitarNomeacaoTomador({ commit }, data) {
      return await instance({
        method: "post",
        url: "/api/PolicyHolder/RegisterPolicyHolderNomeationRequest",
        data
      }).then(response => response.data.Response);
    },
    async inverterIgnoreCCGSignature({ commit }, data) {
      return await instance({
        method: "post",
        url: "/api/PolicyHolder/InverterIgnoreCCGSignaturePolicyHolder",
        data
      }).then(response => response.data.Response);
    },
    async blockUnblockPolicyHolder({ commit }, data) {
      // if (!data.id || !data.justify) return;
      return await instance
        .post(`/api/PolicyHolder/InverterBlockedPolicyHolder`, data)
        .then(response => response.data.Response)
        .then(
          response => commit("updateSearchTomadores", response) || response
        );
    },
    async blockUnblockPolicyHolderRestrictive({ commit }, data) {
      // if (!data.id || !data.justify) return;
      return await instance
        .post(`/api/PolicyHolder/InverterBlockedRestrictvePolicyHolder`, data)
        .then(response => response.data.Response)
        .then(
          response => commit("updateSearchTomadores", response) || response
        );
    },
    async getDocuments({ commit }, data) {
      return await instance
        .get(
          `/api/PolicyHolder/GetDocumentsByPolicyHolderUniqueId?policyHolderUniqueId=${data}`
        )
        .then(response => response.data.Response);
    },
    async uploadDocumentoPolicyHolder({ commit }, data) {
      return await instance({
        method: "post",
        url: "api/PolicyHolder/UploadRequestDocument",
        data
      }).then(response => response.data.Response);
    },
    async uploadDocumentoByRequestLimit({ commit }, data) {
      return await instance({
        method: "post",
        url: "api/PolicyHolder/UploadRequestLimitsDocument",
        data
      }).then(response => response.data.Response);
    },
    async sendRequestToAnalysis({ commit }, data) {
      return await instance({
        method: "post",
        url: "/api/PolicyHolder/sendRequestToAnalysis",
        data
      }).then(response => response.data.Response);
    },
    async deleteRequestDocument({ commit }, data) {
      return await instance({
        method: "post",
        url: "api/PolicyHolder/DeleteRequestDocument",
        data
      }).then(response => response.data.Response);
    },
    async createDocument({ commit }, data) {
      return await instance({
        method: "post",
        url: "api/PolicyHolder/CreateRequestDocument",
        data
      }).then(response => response.data.Response);
    },
    async editNameDocumentPolicyHolder({ commit }, data) {
      return await instance({
        method: "post",
        url: "api/PolicyHolder/ChangeRequestDocumentName",
        data
      }).then(response => response.data.Response);
    },
    async restoreDocumentPolicyHolder({ commit }, data) {
      return await instance({
        method: "post",
        url: "api/PolicyHolder/RestoreRequestDocument",
        data
      }).then(response => response.data.Response);
    },
    async getDocumentoPorId({ commit }, id) {
      return await instance
        .get(
          `/api/Broker/GetBrokerRequestDocumentArchives?RequestDocumentUniqueId=${id}`
        )
        .then(response => response.data.Response);
    },
    limparEstado({ commit }, data) {
      commit("limparEstado", {});
    }
  },
  getters: {
    tomador(state) {
      return state.tomador;
    },
    searchTomadoresResult(state) {
      return state.searchTomadores;
    },
    tomadores(state) {
      return (state.searchTomadores || {}).Data || [];
    },
    novaPropostaLimitesETaxasTomador(state) {
      return state.novaPropostaLimitesETaxasTomador;
    }
  }
};