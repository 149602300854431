import instance from "../../axios";
import * as Utils from "../../utils/utils";

export default {
  namespaced: true,
  state: {
    searchApolices: [],
    apolice: {},
    Rules: {},
    changeTypes: {}
  },
  mutations: {
    updateSearchApolices(state, data) {
      state.searchApolices = data;
    },

    updateApolice(state, data) {
      state.apolice = data;
    },
    updateRules(state, data) {
      state.Rules = data;
    },
    updateChangeTypes(state, data) {
      state.changeTypes = data;
    }
  },
  actions: {
    searchApolices({ commit }, params) {
      return instance
        .get(`/api/Policy/SearchPoliciesRelatedToLoggedUser`, {
          params: {
            policyNumber: params.filter.policyNumber,
            policyHolderName: params.filter.policyHolderName,
            policyHolderDocument: params.filter.policyHolderDocument,
            insuredName: params.filter.insuredName,
            insuredDocument: params.filter.insuredDocument,
            statusId: params.filter.statusId,
            startDate: Utils.dateToDDMMYYYY(params.filter.startDate),
            endDate: Utils.dateToDDMMYYYY(params.filter.endDate),
            brokerName: params.filter.brokerName,
            enableToSteppin: params.filter.enableToSteppin,
            enableToMonitoring: params.filter.enableToMonitoring,
            enableToBigRisk: params.filter.enableToBigRisk,
            brokerDocument: params.filter.brokerDocument,
            currentPage: params.currentPage,
            rowsPerPage: params.rowsPerPage
          }
        })
        .then(response => response.data.Response)
        .then(response => commit("updateSearchApolices", response) || response);
    },

    searchApolice({ commit }, params) {
      return instance
        .get(`/api/Policy/SearchPoliciesRelatedToLoggedUser`, {
          params: {
            policyNumber: params.filter.policyNumber,
            policyHolderName: params.filter.policyHolderName,
            policyHolderDocument: params.filter.policyHolderDocument,
            insuredName: params.filter.insuredName,
            insuredDocument: params.filter.insuredDocument,
            statusId: params.filter.statusId,
            startDate: Utils.dateToDDMMYYYY(params.filter.startDate),
            endDate: Utils.dateToDDMMYYYY(params.filter.endDate),
            brokerName: params.filter.brokerName,
            brokerDocument: params.filter.brokerDocument,
            currentPage: params.currentPage,
            rowsPerPage: params.rowsPerPage
          }
        })
        .then(response => response.data.Response);
    },

    loadApoliceByUniqueId({ commit }, id) {
      return instance
        .get(`/api/Policy/GetPolicyShortBrief?policyUniqueId=${id}`)
        .then(response => response.data.Response)
        .then(response => commit("updateApolice", response) || response);
    },
    loadEndossoRules({ commit }, object) {
      return instance
        .get(
          `/api/Endorsement/GetMinimalEndorsementChanges?PolicyUniqueId=${object.id}&endorsementType=${object.type}`
        )
        .then(response => response.data.Response)
        .then(response => commit("updateRules", response) || response);
    },
    loadEndossoChangeTypes({ commit }, id) {
      return instance
        .get(
          `/api/Endorsement/GetCompanyEndorsementChangeTypes?PolicyUniqueId=${id}`
        )
        .then(response => response)
        .then(response => commit("updateChangeTypes", response) || response);
    },

    searchApolicesTomador({ commit }, tomadorUniqueId) {
      return instance
        .get(
          `/api/Policy/SearchPoliciesRelatedToPolicyHolder?PolicyHolderUniqueId=${tomadorUniqueId}`
        )
        .then(response => response.data.Response);
    },

    searchApolicesSegurado({ commit }, insuredUniqueId) {
      return instance
        .get(
          `/api/Policy/SearchPoliciesRelatedToInsured?UniqueId=${insuredUniqueId}`
        )
        .then(response => response.data.Response);
    },

    downloadApolice({ commit }, payload) {
      return instance
        .get(
          `api/Policy/DownloadPolicyDocument?ProposalUniqueId=${payload.propostaUniqueId}&endorsement=${payload.endorsement}`
        )
        .then(response => response.data.Response.UrlToDownload);
    },

    downloadUltimoEndosso({ commit }, propostaUniqueId) {
      return instance
        .get(
          `api/Policy/DownloadLastEndorsementDocument?ProposalUniqueId=${propostaUniqueId}`
        )
        .then(response => response.data.Response.UrlToDownload);
    },

    loadApolicesHistory({ commit }, policyUniqueid) {
      return instance
        .get(`/api/Policy/GetHistory?policyUniqueid=${policyUniqueid}`)
        .then(response => response.data.Response);
    },

    postInternalCancelPolicy({ commit }, data) {
      return instance
        .post(`api/Policy/InternalCancelPolicy?policyNumber=${data}`)
        .then(response => response.data.Response);
    }
  },
  getters: {
    searchApolicesResult(state) {
      return state.searchApolices;
    },
    apolices(state) {
      return (state.searchApolices || {}).Data || [];
    },
    apolice(state) {
      return state.apolice || {};
    },
    Rules(state) {
      return state.Rules || {};
    },
    changeTypes(state){      
      return state.changeTypes.data.Response
    }
  }
};
